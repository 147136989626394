import Tracker from '@openreplay/tracker';
import Cookies from 'js-cookie';

var initOpts = {
  projectKey: window.theme.orpProjectKey,
  ingestPoint: window.theme.orpIngestPoint,
  //defaultInputMode: 0,
  //obscureTextNumbers: false,
  //obscureTextEmails: true,
  onStart: ({sessionToken}) => {
    Cookies.set('openReplaySessionToken', sessionToken, { path: '/' });
    if (typeof window.Sentry !== 'undefined') {
      window.Sentry.setTag('openReplaySessionToken', sessionToken);
    }
  },
};
console.log('init ORP');
window.OrpTracker = new Tracker(initOpts);

var startOpts = {
  metadata: {
    ip: window.theme.clientIp,
    domain: window.location.hostname.replace(/^www\.(.*\.com)$/, '$1'),
  },
};
window.OrpTracker.start(startOpts);

window.setSentryOrpSessionUrl = function () {
  try {
    if (typeof window.Sentry !== 'undefined') {
      window.Sentry.setTag('openReplaySessionURL', window.OrpTracker.getSessionURL());
    }
  } catch (e) {
    window.captureError('cannot set Sentry tag \'openReplaySessionToken\' with message ' + e.message);
  }
}

window.addEventListener('load', function () {
  window.setSentryOrpSessionUrl();
})
